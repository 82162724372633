/* You can add global styles to this file, and also import other style files */
/* update your 'styles.css' with */
/*@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&family=Roboto+Mono&family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&family=Open+Sans&family=Roboto&family=Roboto+Mono&family=Ubuntu&display=swap');*/
@import '~ngx-sharebuttons/themes/default/default-theme';

html {
  height: 100%;
}

body {
  margin: 0;
  background: #eaedef !important;
  display: flex;
  height: 100%;
  min-height: 100vh;
  flex-direction: column;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.dir-alp {
  background-image: url("assets/images/bg.jpg");
}

.text-hyphens {
  width: 100%;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.ngx-pagination .current {
  padding: 0.1875rem 0.625rem;
  background: #ee6e73 !important;
  cursor: default;
}

.ngx-pagination li {
  border-bottom: none !important;
  height: 30px;
  padding: 0 !important;
  display: inline-block;
  border-radius: 2px !important;
  text-align: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  -webkit-transition: background-color 0.5s ease;
  -moz-transition: background-color 0.5s ease;
  -o-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
}

.ngx-pagination * {
  outline: -webkit-focus-ring-color auto 0 !important;
}

.ngx-pagination li span {
  display: inline-block;
  font-size: 1.2rem;
  padding: 0 10px;
  line-height: 30px;
}

.ngx-pagination li.current span {
  color: #fff !important;
}

.ngx-pagination .pagination-next,
.ngx-pagination .pagination-previous {
  width: 32px;
  font-size: 14px;
  display: inline-block;
  margin: 4px 0 0;
  color: #444;
}

.ngx-pagination .pagination-previous {
  text-align: left;
}

.ngx-pagination .pagination-next {
  text-align: right;
}

.ngx-pagination .pagination-previous.disabled::after {
  content: "\f053";
  font-family: FontAwesome;
  position: absolute;
  left: 0;
}

.ngx-pagination .pagination-next.disabled::after {
  content: "\f054";
  font-family: FontAwesome;
}

.ngx-pagination .pagination-next.disabled,
.ngx-pagination .pagination-previous.disabled {
  color: #999;
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before {
  content: "\f053" !important;
  font-family: FontAwesome !important;
  margin-right: 0 !important;
}

.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after {
  content: "\f054" !important;
  font-family: FontAwesome !important;
  margin-left: 0 !important;
}

.ngx-pagination a, .ngx-pagination button {
  padding: 0 !important;
  color: #444 !important;
}

.ngx-pagination a:hover, .ngx-pagination button:hover {
  background: none !important;
}

.dir-alp {
  background: none;
}

.app-content {
  flex: 1;
}

.tz-register {
  background: #eaedef !important;
}

.tz-register::before {
  background: none !important;
}

a:focus, a:hover {
  text-decoration: none;
}

.span-link {
  margin-top: 5px;
  background: #20344c;
  color: #fff;
  font-weight: 600;
  padding: 5px;
  border-radius: 2px;
  font-size: 12px;
  border: 1px solid #1d2b3c;
  cursor: pointer;
}

.span-link:hover {
  color: #fff;
  background: #14addb;
  border: 1px solid #0885ab;
}

.alert-danger {
  margin-top: 5px;
}

.container.top-search-main {
  width: 97%;
}

a {
  cursor: pointer;
}
.log-in-pop {
  display: flex;
}
.log-in-pop-left {
  height: auto;
}
.log-in-pop-left p a {
  color: #4a4949;
}
.log-in-pop-left p a:hover {
  color: #333;
}
.log-in-pop-right p a {
  color: #0465AB;
}
.log-in-pop-right p a:hover {
  color: #333;
}
.v3-top-ri ul li a.active {
  background: #14addb;
  cursor: default;
}
.social-icon {
  width: 24px;
  float: left;
  text-align: left;
}
.inDesktop {
  display: block;
}
.inMobile {
  display: none;
}

.v3-list-ql {
  z-index: 90 !important;
}

.inline {
  display: inline-block;
}

.db-list-status {
  background: #35d8a5;
}

.db-list-status-na {
  background: #fd8478;
}

.db-list-status-unpublished {
  background: #449fad;
}

.db-list-status-in-moderation {
  background: #eb9561;
}

mat-select {
  background: #fff;
}

.ellipsis-style {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-style-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.share-btn ul li {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 15px;
  line-height: 32px;
}
@media screen and (min-width: 1200px) {
  .log-in-pop {
    width: 80%;
  }
  .tourz-top-search-form {
    max-width: 660px;
    margin: 11px auto 0;
  }
  .ts-menu-2 {
    width: 8%;
  }
  .ts-menu-3 {
    width: 49%;
    padding: 0;
  }
  .ts-menu-4 {
    width: 35%;
    padding: 0;
  }
  .tourz-top-search-form div:nth-child(2) {
    width: 80%;
    margin-left: 2.5%;
  }
  .tourz-search-form .input-field:last-child {
    width: 15%;
  }
}
/* On screens that are 992px wide or less */
@media screen and (max-width: 992px) {
  .share-btn {
    margin: 5px auto;
  }
  .share-btn ul li {
    display: block;
    margin-bottom: 5px;
    margin-right: 1px;
    width: 40mm;
  }
  .share-btn ul li:nth-child(1) {
    background: #3b5998;
  }

  .share-btn ul li:nth-child(2) {
    background: #00aced;
  }

  .share-btn ul li:nth-child(3) {
    background: #0a73b0;
  }
}

@media screen and (max-width: 767px) {
  .inDesktop {
    display: none;
  }
  .inMobile {
    display: block;
  }
  .log-in-pop-right {
    float: none;
  }
  .log-in-pop-left.inMobile {
    display: block;
    width: 100%;
    float: none;
  }
  .log-in-pop {
    display: block !important;
  }
  .toast-container {
    transform: translateX(25%);
    max-width: 50%;
    width: 50% !important;
    min-width: inherit !important;
    right: inherit !important;
  }
}
.no_data_message {
  padding: 20px;
  font-size: 16px;
  line-height: 25px;
}
.button {
  font-size: 14px;
  border: none;
  border-radius: 2px;
  display: inline-block;
  height: 40px;
  line-height: 36px;
  margin-left: 10px;
  margin-right: 10px;
  text-transform: uppercase;
  vertical-align: middle;
  color: white;
}
.button:focus {
    background-color: #14addb !important;
}
.section-button {
  float: right;
  height: 35px;
  width: 100px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  letter-spacing: .5px;
  transition: .2s ease-out;
  cursor: pointer;
  color: #fff;
  background: #14addb;
  font-weight: 600;
  border: none;
  border-radius: 2px;
}
.section-button:focus {
  background-color: #14addb !important;
}
.section-button:hover {
  //background: #263a78;
  background: linear-gradient(to bottom, #455ca2, #263a78);
  color: #fff;
}
.pg-list-1 {
  margin-top: 0;
  padding: 100px 0 200px;
  background: #41809b;
  background-size: auto 100% !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width:1920px;
  position: center;
}
.tz-3 ul li a h5 {
  text-transform: none !important;
}
.owl-carousel {
  width: 90% !important;
  margin: 5% 5% 0 5%;
  height: 340px;
}

.owl-carousel .owl-nav {
  overflow: hidden;
  height: 0px;
}

.owl-carousel .nav-btn {
  height: 47px;
  position: absolute;
  width: 26px;
  cursor: pointer;
  top: 30% !important;
}
.owl-carousel .owl-stage-outer {
  width: 100% !important;
}

.owl-carousel .owl-prev.disabled,
.owl-carousel .owl-next.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.owl-carousel .prev-slide {
  background: url("assets/images/nav-icon.png") no-repeat scroll 0 0;
  left: -33px;
}

.owl-carousel .next-slide {
  background: url("assets/images/nav-icon.png") no-repeat scroll -24px 0px;
  right: -33px;
}

.owl-carousel .prev-slide:hover {
  background-position: 0px -53px;
}

.owl-carousel .next-slide:hover {
  background-position: -24px -53px;
}
.owl-carousel .owl-item {
  //margin: 10px;
}
@media only screen and (max-width: 500px) {
  .owl-carousel .owl-item {
    padding-top: 20px;
  }
}
@media only screen and (max-width: 400px) {
  .owl-carousel .owl-item {
    padding-top: 20px;
  }
}
@media only screen and (max-width: 380px) {
  .owl-carousel .owl-item {
    padding-top: 20px;
  }
}
@media only screen and (max-width: 350px) {
  .owl-carousel .owl-item {
    padding-top: 20px;
  }
}

p {
  white-space: break-spaces;
}

.tz-2 {
  width: 78% !important;
}
@media screen and (max-width: 1330px) {
   .tz-2 {
      width: 68% !important;
    }
}
@media screen and (max-width: 767px) {
  .tz-2 {
        width: 100% !important;
    }
}

.remove_exp_button {
  background: #F44336 !important;
  border: 1px solid #dc2e21 !important;
}
.see_exp_button{
  background: #14addb !important;
  border: 1px solid #14addb !important;
  color: #fff !important;
}
