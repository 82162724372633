@import 'variables';

.sb-group,
.sb-button {
  display: inline-flex;
  align-items: flex-start;
}

.sb-group {
  flex-wrap: wrap;
}

.sb-button {
  margin: var(--sb-margin);
}

/** Default style */

.sb-wrapper {
  font-size: $sb-font-size;
  cursor: pointer;
  position: relative;
  outline: 0;
  min-width: $sb-min-width;
  height: $sb-height;
  border: $sb-border;
  border-radius: $sb-border-radius;
  padding: $sb-padding;
  line-height: $sb-line-height;
  background-color: $sb-background-color;
  -webkit-print-color-adjust: exact;
  .sb-icon,
  .sb-text {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
  }
  .sb-content {
    flex: 1;
    display: flex;
    height: 100%;
    width: 100%;
    // Fix clipping the shadow in circle-dark theme in Safari
    position: relative;
  }
  .sb-text {
    flex: 1;
    height: 100%;
    white-space: nowrap;
    padding: $sb-text-padding;
  }
  .sb-icon {
    text-align: center;
    width: 100%;
    height: 100%;
    font-size: $sb-icon-size;
    min-width: 2em;
  }
  .sb-text {
    font-weight: bold;
  }
}
